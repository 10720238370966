import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faUsers, faChartLine, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  const blockVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    hover: { scale: 1.05 },
    tap: { scale: 0.95 }
  };

  const workflowVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.3, duration: 0.5 } }
  };

  return (
    <motion.section id="about" className="min-h-screen flex flex-col items-center justify-center bg-gray-300 px-8 py-16">
      <div className="text-center">
        <h2 className="text-3xl font-bold mb-8">About Us</h2>
        <p className="text-lg mb-8">
          We are a dedicated team of professionals committed to accelerating businesses and individuals towards success. With our expertise and innovative solutions, we strive to provide fast, efficient, and ready-to-use solutions tailored to meet the unique needs of our clients.
        </p>
        <motion.div className="grid grid-cols-1 md:grid-cols-3 gap-8" variants={blockVariants} initial="initial" animate="animate">
          {/* Displaying blocks */}
          <motion.div className="bg-white rounded-lg p-6 shadow-md flex flex-col items-center" variants={blockVariants} whileHover="hover" whileTap="tap">
            <FontAwesomeIcon icon={faUser} className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">Who We Are</h3>
            <p className="text-gray-700 text-center">
              We are a team of experienced professionals dedicated to providing innovative solutions to our clients.
            </p>
          </motion.div>
          <motion.div className="bg-white rounded-lg p-6 shadow-md flex flex-col items-center" variants={blockVariants} whileHover="hover" whileTap="tap">
            <FontAwesomeIcon icon={faCog} className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">What We Do (AI ML)</h3>
            <p className="text-gray-700 text-center">
              Our expertise lies in Artificial Intelligence and Machine Learning technologies. We develop advanced AI solutions to optimize business processes and drive growth.
            </p>
          </motion.div>
          <motion.div className="bg-white rounded-lg p-6 shadow-md flex flex-col items-center" variants={blockVariants} whileHover="hover" whileTap="tap">
            <FontAwesomeIcon icon={faUsers} className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">20+ People Team</h3>
            <p className="text-gray-700 text-center">
              Our team consists of over 20 talented professionals with diverse backgrounds and skills. We collaborate seamlessly to deliver exceptional results for our clients.
            </p>
          </motion.div>
          <motion.div className="bg-white rounded-lg p-6 shadow-md flex flex-col items-center" variants={blockVariants} whileHover="hover" whileTap="tap">
            <FontAwesomeIcon icon={faChartLine} className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">Variety of Domains</h3>
            <p className="text-gray-700 text-center">
              We specialize in a wide range of domains, including healthcare, finance, retail, and more. Our expertise allows us to address diverse business challenges and deliver comprehensive solutions.
            </p>
          </motion.div>
          <motion.div className="bg-white rounded-lg p-6 shadow-md flex flex-col items-center" variants={blockVariants} whileHover="hover" whileTap="tap">
            <FontAwesomeIcon icon={faCheckCircle} className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">Efficient Solutions</h3>
            <p className="text-gray-700 text-center">
              We are committed to delivering efficient solutions that meet the highest standards of quality and reliability. Our goal is to empower businesses with innovative technologies that drive success.
            </p>
          </motion.div>
        </motion.div>
        {/* Workflow diagram */}
        <motion.div className="mt-12 flex flex-wrap justify-center items-center" variants={workflowVariants} initial="initial" animate="animate">
          {/* First stage */}
          <motion.div className="flex items-center mb-4" variants={blockVariants} whileHover="hover" whileTap="tap">
            <div className="w-16 h-0.5 bg-black"></div>
            <div className="bg-black rounded-full w-8 h-8 flex items-center justify-center -ml-4">
              <span className="text-white">1</span>
            </div>
            <div className="w-16 h-0.5 bg-black"></div>
          </motion.div>
          <div className="text-center mx-4">
            <h3 className="text-lg font-bold">Requirement Gathering</h3>
            <p className="text-sm text-gray-600">Gather client requirements</p>
          </div>
          {/* Second stage */}
          <motion.div className="flex items-center mb-4" variants={blockVariants} whileHover="hover" whileTap="tap">
            <div className="w-16 h-0.5 bg-black"></div>
            <div className="bg-black rounded-full w-8 h-8 flex items-center justify-center -ml-4">
              <span className="text-white">2</span>
            </div>
            <div className="w-16 h-0.5 bg-black"></div>
          </motion.div>
          <div className="text-center mx-4">
            <h3 className="text-lg font-bold">Analysis</h3>
            <p className="text-sm text-gray-600">Perform project analysis</p>
          </div>
          {/* Third stage */}
          <motion.div className="flex items-center mb-4" variants={blockVariants} whileHover="hover" whileTap="tap">
            <div className="w-16 h-0.5 bg-black"></div>
            <div className="bg-black rounded-full w-8 h-8 flex items-center justify-center -ml-4">
              <span className="text-white">3</span>
            </div>
            <div className="w-16 h-0.5 bg-black"></div>
          </motion.div>
          <div className="text-center mx-4">
            <h3 className="text-lg font-bold">Feature Development</h3>
            <p className="text-sm text-gray-600">Develop project features</p>
          </div>
          {/* Fourth stage */}
          <motion.div className="flex items-center mb-4" variants={blockVariants} whileHover="hover" whileTap="tap">
            <div className="w-16 h-0.5 bg-black"></div>
            <div className="bg-black rounded-full w-8 h-8 flex items-center justify-center -ml-4">
              <span className="text-white">4</span>
            </div>
            <div className="w-16 h-0.5 bg-black"></div>
          </motion.div>
          <div className="text-center mx-4">
            <h3 className="text-lg font-bold">Testing</h3>
            <p className="text-sm text-gray-600">Test project functionalities</p>
          </div>
          {/* Fifth stage */}
          <motion.div className="flex items-center mb-4" variants={blockVariants} whileHover="hover" whileTap="tap">
            <div className="w-16 h-0.5 bg-black"></div>
            <div className="bg-black rounded-full w-8 h-8 flex items-center justify-center -ml-4">
              <span className="text-white">5</span>
            </div>
            <div className="w-16 h-0.5 bg-black"></div>
          </motion.div>
          <div className="text-center mx-4">
            <h3 className="text-lg font-bold">Support & Maintenance</h3>
            <p className="text-sm text-gray-600">Provide ongoing support</p>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default About;
