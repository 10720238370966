import React, { useState, useEffect } from 'react';
import { motion, useViewportScroll } from 'framer-motion';

const Home = () => {
  const { scrollYProgress } = useViewportScroll();
  const [currentShapes, setCurrentShapes] = useState([]);
  const shapes = ['moon', 'sun', 'sphere', 'star', 'heart'];
  const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF'];
  const slogan = 'Innovation for Tomorrow';

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentShapes.length < shapes.length) {
        setCurrentShapes(shapes.slice(0, currentShapes.length + 1));
      } else {
        setCurrentShapes([]);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [currentShapes, shapes]);

  return (
    <motion.section id="home" className="min-h-screen flex flex-col items-center justify-center bg-black text-white relative overflow-hidden">
      <div className="text-center z-10 relative">
        <motion.div
          className="mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1, type: 'spring', stiffness: 120 }}
        >
          <h1 className="text-4xl font-bold">
            Shaping Tomorrow with <span className="text-red-500 playfair-display">A</span><span className='text-blue-500 playfair-display'>dvansphere</span>
          </h1>
          <p className="text-lg font-bold text-red-500 dancing-script">{slogan}</p> {/* Adjusted font size and color */}
          <div className="w-36 h-0.5 bg-white mx-auto mb-4"></div> {/* White line */}
        </motion.div>
        <motion.div
          className="mb-4 flex justify-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 1, type: 'spring', stiffness: 120 }}
        >
          {currentShapes.map((shape, index) => (
            <motion.svg
              key={index}
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              className="w-8 h-8 mx-2"
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, type: 'spring', stiffness: 120, delay: index * 0.3 }}
            >
              <circle cx="20" cy="20" r="20" fill={colors[index]} />
            </motion.svg>
          ))}
        </motion.div>
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        <svg
          className="w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 5"
          preserveAspectRatio="none"
        >
          <motion.path
            fill="#F3F4F6"
            fillOpacity="1"
            d="M0,0L1440,0L1440,5L0,5Z"
            style={{
              scaleY: scrollYProgress,
            }}
          ></motion.path>
        </svg>
      </div>
    </motion.section>
  );
};

export default Home;
