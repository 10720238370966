import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faMoneyBillAlt, faUserGraduate, faGift, faTimes } from '@fortawesome/free-solid-svg-icons';

const Jobs = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  
  const internshipPositions = [
    {
      id: 1,
      title: 'Blockchain Developer Intern',
      image: require('../img/blockchain-developer.jpg'),
      description: 'Exciting opportunity for a Blockchain Developer to join our team as an intern. As a Blockchain Developer Intern, you will assist in designing and implementing blockchain solutions for various applications.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Blockchain Developer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Blockchain Development', 'Smart Contracts', 'Solidity', 'Decentralized Applications']
      }
    },
    {
      id: 2,
      title: 'Data Scientist Intern',
      image: require('../img/data-scientist.jpg'),
      description: 'Exciting opportunity for a Data Scientist to join our team as an intern. As a Data Scientist Intern, you will assist in analyzing complex datasets, developing machine learning models, and providing actionable insights.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Data Scientist Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Data Analysis', 'Machine Learning', 'Python', 'SQL']
      }
    },
    {
      id: 3,
      title: 'Database Engineer Intern',
      image: require('../img/database.jpg'),
      description: 'Exciting opportunity for a Database Engineer to join our team as an intern. As a Database Engineer Intern, you will assist in designing, implementing, and maintaining databases to ensure optimal performance and data integrity.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Database Engineer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Database Management', 'SQL', 'Database Optimization', 'Data Modeling']
      }
    },
    {
      id: 4,
      title: 'Graphic Designer Intern',
      image: require('../img/graphic-designer.png'),
      description: 'Exciting opportunity for a Graphic Designer to join our team as an intern. As a Graphic Designer Intern, you will assist in creating visually appealing designs for various digital and print media.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Graphic Designer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Graphic Design', 'Adobe Creative Suite', 'Typography', 'Illustration']
      }
    },
    {
      id: 5,
      title: 'Machine Learning Engineer Intern',
      image: require('../img/machine-learning-engineer.jpg'),
      description: 'Exciting opportunity for a Machine Learning Engineer to join our team as an intern. As a Machine Learning Engineer Intern, you will assist in developing and implementing machine learning algorithms and models for various applications.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Machine Learning Engineer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Machine Learning', 'Python', 'TensorFlow', 'Deep Learning']
      }
    },
    {
      id: 6,
      title: 'Mobile App Developer Intern',
      image: require('../img/mobile-app-developer.jpg'),
      description: 'Exciting opportunity for a Mobile App Developer to join our team as an intern. As a Mobile App Developer Intern, you will assist in designing and developing mobile applications for iOS and Android platforms.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Mobile App Developer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Mobile App Development', 'React Native', 'Swift', 'Kotlin']
      }
    },
    {
      id: 7,
      title: 'Frontend Web Developer Intern',
      image: require('../img/website-developer.jpg'),
      description: 'Exciting opportunity for a Frontend Web Developer to join our team as an intern. As a Frontend Web Developer Intern, you will assist in designing and implementing user interfaces for web applications using modern frontend technologies.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Frontend Web Developer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['HTML', 'CSS', 'JavaScript', 'React.js']
      }
    },
    {
      id: 8,
      title: 'Software Developer Intern',
      image: require('../img/software-developer.jpg'),
      description: 'Exciting opportunity for a Software Developer to join our team as an intern. As a Software Developer Intern, you will assist in designing, developing, and testing software applications for various platforms.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Software Developer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Software Development', 'Java', 'C++', 'C#']
      }
    },
    {
      id: 9,
      title: 'Prompt Engineer Intern',
      image: require('../img/prompt-engineer.jpg'),
      description: 'Exciting opportunity for a Prompt Engineer to join our team as an intern. As a Prompt Engineer Intern, you will assist in troubleshooting and resolving technical issues for prompt response systems.',
      benefits: {
        location: 'Remote',
        stipend: 'Based on performance',
        experience: '0-1 years',
        role: 'Prompt Engineer Intern',
        perks: ['Flexible Hours', 'Mentorship', 'Certificate', 'Goodies'],
        skills: ['Troubleshooting', 'Technical Support', 'Prompt Response Systems', 'Customer Service']
      }
    },
  ];

  const handleApplyNow = (job) => {
    setSelectedJob(job);
  };

  const handleClosePopup = () => {
    setSelectedJob(null);
  };

  const renderJobs = () => {
    return internshipPositions.map(job => (
      <div key={job.id} className="mx-2">
        <div className="relative">
          <img
            src={job.image}
            alt={job.title}
            className="w-full h-80 object-cover rounded-lg hover:opacity-75 transition duration-300 cursor-pointer"
            onClick={() => handleApplyNow(job)}
          />
          <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-0 transition duration-300 hover:bg-opacity-50">
            <h3 className="text-xl font-bold mb-2 text-white">{job.title}</h3>
            <button
              className="bg-transparent text-white py-2 px-4 rounded-lg border border-white hover:bg-white hover:text-blue-500 transition duration-300"
              onClick={() => handleApplyNow(job)}
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
    ));
  };

  const popupVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1, transition: { duration: 0.3, type: 'spring', stiffness: 100 } }
  };

  return (
    <motion.div id="jobs" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4 mt-8 mb-16">
        <h2 className="text-3xl font-bold text-center mb-8">Internship Positions</h2>
        <p className="text-lg text-center mb-8">Explore our exciting internship opportunities below.</p>
        <Slider
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={3000}
          cssEase="ease"
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            }
          ]}
        >
          {renderJobs()}
        </Slider>
        {selectedJob && (
          <motion.div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" variants={popupVariants} initial="hidden" animate="visible">
            <div className="bg-white rounded-lg p-8 max-w-2xl">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold">{selectedJob.title}</h3>
                <button className="text-gray-500 hover:text-gray-700 transition duration-300" onClick={handleClosePopup}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="mb-4">{selectedJob.description}</p>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-red-500 mr-2" />
                  <p><strong>Location:</strong> {selectedJob.benefits.location}</p>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faMoneyBillAlt} className="text-red-500 mr-2" />
                  <p><strong>Stipend:</strong> {selectedJob.benefits.stipend}</p>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faUserGraduate} className="text-red-500 mr-2" />
                  <p><strong>Experience:</strong> {selectedJob.benefits.experience}</p>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faUserGraduate} className="text-red-500 mr-2" />
                  <p><strong>Role:</strong> {selectedJob.benefits.role}</p>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faGift} className="text-red-500 mr-2" />
                  <p><strong>Perks:</strong> {selectedJob.benefits.perks.join(', ')}</p>
                </div>
                <div className="flex items-center">
                  <p>Send your CV and resume to <a href="mailto:careers@advansphere.com" className="text-blue-500 hover:underline">careers@advansphere.com</a></p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default Jobs;
