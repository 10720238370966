import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Jobs from './components/Jobs';
import Footer from './components/Footer';
import Contact from "./components/Contact"
import './styles.css'; 

function App() {
  const [loading, setLoading] = useState(true);

  // Simulating loading time with setTimeout
  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <AnimatePresence mode='wait'
>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Since this is a single-page application, no need for separate routes */}
          </Routes>
        </AnimatePresence>
        {loading && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white z-50"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="rounded-full h-24 w-24 bg-transparent flex justify-center items-center border-4 border-red-500"
              animate={{ rotate: 360 }}
              transition={{ duration: 2, loop: Infinity, ease: "linear" }}
            >
              {/* Show bigger name "Advansphere" or just the letter "A" */}
              <span className="text-red-500 font-bold text-4xl">A</span>
            </motion.div>
          </motion.div>
        )}
        {/* <Home /> */}
        <Services />
        <About />
        <Jobs />
        <Contact/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
