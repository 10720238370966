import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import advanspherLogo from "../img/advansphereLogo.png";

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsScrolled(scrollPosition > 0);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.header
      className={`fixed top-0 w-full py-4 z-10 ${isScrolled ? 'bg-black' : 'bg-transparent'}`}
      initial={{ backgroundColor: 'transparent' }}
      animate={{ backgroundColor: isScrolled ? '#000' : 'transparent' }}
      transition={{ duration: 0.3 }}
    >
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <img src={advanspherLogo} alt="Advansphere Logo" className="h-8" />
        </Link>
        <button className="block lg:hidden focus:outline-none" onClick={toggleMenu}>
          {isMenuOpen ? (
            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          )}
        </button>
        <nav className={`lg:flex ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="flex flex-col lg:flex-row lg:space-x-4 lg:items-center">
            <NavLink to="home" onClick={toggleMenu}>Home</NavLink>
            <NavLink to="services" onClick={toggleMenu}>Services</NavLink>
            <NavLink to="about" onClick={toggleMenu}>About Us</NavLink>
            <NavLink to="jobs" onClick={toggleMenu}>Jobs</NavLink>
            <NavLink to="contact" onClick={toggleMenu}>Get Quote</NavLink>
          </div>
        </nav>
      </div>
      <div className="bg-white h-1 w-full" style={{ width: '100%', height: '1px' }} />
    </motion.header>
  );
};

const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === `/${to}`;

  return (
    <ScrollLink
      to={to}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      className={`relative text-white hover:text-red-500 transition duration-300 ${isActive ? 'font-bold text-red-500' : ''}`}
      onClick={onClick}
    >
      {children}
      {isActive && <span className="absolute bottom-0 left-0 bg-red-500 h-1 w-full" />}
    </ScrollLink>
  );
};

export default Header;
