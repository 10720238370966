import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto text-center">
        <motion.div
          className="flex flex-wrap justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <motion.a
            href="#"
            className="text-white"
            whileHover={{ color: '#3b5998' }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon icon={faFacebookF} size="2x" />
          </motion.a>
          <motion.a
            href="https://twitter.com/advansphere"
            className="text-white"
            whileHover={{ color: '#1da1f2' }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </motion.a>
          <motion.a
            href="https://www.linkedin.com/company/advansphere/"
            className="text-white"
            whileHover={{ color: '#0e76a8' }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </motion.a>
          <motion.a
            href=""
            className="text-white"
            whileHover={{ color: '#c13584' }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </motion.a>
          <motion.a
            href="mailto:office@advansphere.com"
            className="text-white"
            whileHover={{ color: '#e64d1d' }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </motion.a>
        </motion.div>
        <motion.div
          className="mt-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.5, duration: 1 }}
        >
          <hr className="border-t border-gray-600" />
        </motion.div>
        <motion.p
          className="mt-4 text-red-500 text-lg font-bold"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2, duration: 1 }}
        >
          © 2024 Advansphere
        </motion.p>
        <motion.p
          className="text-blue-500 text-sm opacity-50"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2, duration: 1 }}
        >
          Created with ❤️ by Advansphere
        </motion.p>
      </div>
    </footer>
  );
};

export default Footer;
