import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    businessName: '',
    country: '',
    message: ''
  });
  const textareaRef = useRef(null);

  useEffect(() => {
    // Automatically adjust textarea height based on content
    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set a maximum height
    };
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [formData.message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    if (formData.name && formData.email && formData.message) {
      // Display success toast for quote submission
      toast.success('Quote submitted successfully', {
        position: 'top-center'
      });
      // Clear form data
      setFormData({
        name: '',
        email: '',
        businessName: '',
        country: '',
        message: ''
      });
    } else {
      // Display error toast if any required field is empty
      toast.error('Please fill in all required fields', {
        position: 'top-center'
      });
    }
  };

  return (
    
    <motion.section id='contact' className="py-16 bg-gray-100" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
    <h2 className="text-3xl font-bold mb-4 text-center">Get a Quote</h2>
      <div className="container mx-auto text-center">
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white p-8 rounded shadow-md" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
          <div className="mb-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              placeholder="Business Name"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <textarea
              ref={textareaRef}
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 resize-none"
              rows="1"
              required
            ></textarea>
          </div>
          <button type="submit" className="w-full bg-pink-500 text-white py-2 px-4 rounded-md hover:bg-pink-600 transition duration-300">Get Quote</button>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </motion.section>
  );
};

export default Contact;
