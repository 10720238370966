import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faCode, faCubes, faLaptopCode, faBrain, faChartBar } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const services = [
    { icon: faCode, title: 'App Development', description: 'We specialize in developing mobile applications tailored to meet your unique business needs. Our team utilizes the latest technologies to create robust and user-friendly apps for both iOS and Android platforms.' },
    { icon: faCubes, title: 'Software Development', description: 'Our software development services encompass a wide range of solutions, including desktop applications, enterprise software, and custom solutions. We leverage agile methodologies to deliver high-quality software products.' },
    { icon: faLaptopCode, title: 'Website Development', description: 'We offer professional website development services to help businesses establish a strong online presence. Our websites are responsive, SEO-friendly, and designed to engage and convert visitors into customers.' },
    { icon: faBrain, title: 'AI ML Integration', description: 'We specialize in integrating artificial intelligence and machine learning capabilities into existing systems and applications. Our AI solutions empower businesses to automate processes, gain insights, and enhance decision-making.' },
    { icon: faDatabase, title: 'Database Management', description: 'Our database management services ensure the security, reliability, and performance of your data infrastructure. We design, implement, and maintain databases to support your business operations effectively.' },
    { icon: faChartBar, title: 'Live Data Analysis Dashboard', description: 'Our live data analysis dashboards provide real-time insights into your business performance. With interactive visualizations and customizable reports, you can make data-driven decisions and drive growth.' },
  ];

  return (
    <motion.section id= "services" className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white">
      <motion.div
        className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      ></motion.div>
      <motion.div
        className="absolute top-0 left-0 w-full h-full bg-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      ></motion.div>
      <motion.h2
        className="text-3xl font-bold mb-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        Our Services
      </motion.h2>
      <motion.div className="flex flex-wrap justify-center">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 py-2 text-center"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2 * index, duration: 0.5 }}
          >
            <FontAwesomeIcon icon={service.icon} className="text-red-500 text-4xl mb-2" />
            <h3 className="text-lg font-semibold">{service.title}</h3>
            <p>{service.description}</p>
          </motion.div>
        ))}
      </motion.div>
    </motion.section>
  );
};

export default Services;
